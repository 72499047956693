import type { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box, Collapse, Typography } from "@mui/material";

import { useAppSelector } from "../../../../../../../hooks/storeHooks";
import { selectUserData } from "../../../../../../../store/auth/auth.selectors";
import { useGetReportByIdQuery } from "../../../../../../../store/reports/reports.api";
import { AutocompleteController } from "../../../../../../../components/common/AutocompleteController/AutocompleteController.component";
import { Button } from "../../../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { InfoBox } from "../../../../../../../components/common/InfoBox/InfoBox.component";
import SvgIcon from "../../../../../../../components/common/SvgIcon/SvgIcon.component";
import { TextFieldController } from "../../../../../../../components/common/TextFieldController/TextFieldController.component";
import { TextFieldSkeleton } from "../../../../../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import { CollapseFieldset } from "./CollapseFieldset/CollapseFieldset.component";
import type { GeneralInformationFormProps } from "./GeneralInformationForm.types";
import { StyledAutocompleteController } from "./GeneralInformationForm.styles";

import { useGeneralInformationForm } from "./GeneralInformationForm.hooks";
import { FIELDS_MAX_LENGTH, PRODUCT_DETAILS_MAX_LENGTH } from "./GeneralInformationForm.validation";

export const GeneralInformationForm: FC<GeneralInformationFormProps> = ({ headerId, defaultValues }) => {
  const { t } = useTranslation()
  const {
    form,
    handleSubmit,
    parsedCountries,
    isLoading,
    options,
    firstAuditSelected,
  } = useGeneralInformationForm({ headerId, defaultValues })

  const { reportId } = useParams()
  const { data: report } = useGetReportByIdQuery(Number(reportId))
  const userData = useAppSelector(selectUserData)

  const languageMatch = userData.languageCode === report?.languageCode

  return (
    <FormProvider {...form}>
      <Box 
        component="form"
        onSubmit={handleSubmit}
      >
        <Box maxWidth={500}>
          {defaultValues?.basedOnId ? (
            <InfoBox
              type="error"
              mb={2}
            >
              {t("reports:generalInformationStep:dataCopiedFromPrevReport")}
            </InfoBox>
          ) : null}

          {!languageMatch ? (
            <InfoBox
              type="info"
              mb={2}
              sx={{ border: "1px solid", borderColor: "warning.main" }}
            >
              {t("reports:generalInformationStep:fillInCorrectLanguageReminder", { lang: report?.language })}&nbsp;
              <span style={{ fontWeight: 500 }}>
                {report?.language}.
              </span>
            </InfoBox>
          ) : null}

          <Box mb={2}>
            <TextFieldController
              name="companyName"
              label={t("reports:generalInformationStep:companyNameLabel")}
              placeholder=""
              disabled={isLoading}
            />
          </Box>

          <Box mb={2}>
            <Typography
              variant="body1"
              fontWeight={500}
              mb={0.5}
            >
              {t("reports:generalInformationStep:countriesLabel")}
            </Typography>

            {parsedCountries?.length ? (
              <StyledAutocompleteController
                multiple
                name="countries"
                options={parsedCountries}
                label=""
                placeholder={t("form:placeholder:country")}
                ChipProps={{ deleteIcon: <SvgIcon name="close" /> }}
                disabled={isLoading}
              />
            ) : <TextFieldSkeleton />}

            <InfoBox
              type="info"
              mt={2}
              sx={{ border: "1px solid #759FE1" }}
            >
              {t("reports:generalInformationStep:countriesDescription")}
            </InfoBox>
          </Box>

          <Box mb={2}>
            <Typography
              variant="body1"
              fontWeight={500}
              mb={0.5}
            >
              {t("reports:generalInformationStep:productionLabel")}
            </Typography>

            <Typography
              variant="body1"
              fontWeight={500}
              color="text.secondary"
              mb={2}
            >
              {t("reports:generalInformationStep:productionExample")}
            </Typography>

            <TextFieldController
              name="production"
              label=""
              placeholder={t("reports:generalInformationStep:productionPlaceholder")}
              multiline
              minRows={5}
              maxLength={FIELDS_MAX_LENGTH + 1}
              disabled={isLoading}
            />

            <InfoBox
              type="info"
              mt={2}
              sx={{ border: "1px solid #759FE1" }}
            >
              {t("reports:generalInformationStep:productionDescription")}
            </InfoBox>
          </Box>

          <Box mb={2}>
            <Typography
              variant="body1"
              fontWeight={500}
              mb={0.5}
            >
              {t("reports:generalInformationStep:productionDetailsLabel")}
            </Typography>

            <Typography
              variant="body1"
              fontWeight={500}
              color="text.secondary"
              mb={2}
            >
              {t("reports:generalInformationStep:productionDetailsExamplePart1")}&nbsp;
              <Box
                component="span"
                color="error.main"
              >
                {t("reports:generalInformationStep:productionDetailsExamplePart2")}
              </Box>
            </Typography>

            <TextFieldController
              name="policies"
              label=""
              placeholder={t("reports:generalInformationStep:productionDetailsPlaceholder")}
              multiline
              minRows={5}
              maxLength={PRODUCT_DETAILS_MAX_LENGTH + 1}
              disabled={isLoading}
            />
          </Box>

          <Box mb={2}>
            <CollapseFieldset
              name="investments"
              textFieldName="investmentComments"
              label={t("reports:generalInformationStep:investmentsLabel")}
              textFieldLabel={t("reports:generalInformationStep:changesLabel")}
              textFieldHint={t("reports:generalInformationStep:investmentsExample")}
              textFieldPlaceholder={t("reports:generalInformationStep:investmentsPlaceholder")}
              disabled={isLoading}
            />
          </Box>

          <Box mb={2}>
            <CollapseFieldset
              name="energyChanges"
              textFieldName="energyComments"
              label={t("reports:generalInformationStep:energyChangesLabel")}
              textFieldLabel={t("reports:generalInformationStep:changesLabel")}
              textFieldHint={t("reports:generalInformationStep:energyChangesExample")}
              textFieldPlaceholder={t("reports:generalInformationStep:energyChangesPlaceholder")}
              disabled={isLoading}
            />
          </Box>

          <Box mb={2}>
            <CollapseFieldset
              name="processChanges"
              textFieldName="processComments"
              label={t("reports:generalInformationStep:processChangesLabel")}
              textFieldLabel={t("reports:generalInformationStep:changesLabel")}
              textFieldHint={t("reports:generalInformationStep:processChangesExample")}
              textFieldPlaceholder={t("reports:generalInformationStep:processChangesPlaceholder")}
              disabled={isLoading}
            />
          </Box>

          <Box mb={2}>
            <CollapseFieldset
              name="vehicleChanges"
              textFieldName="vehicleComments"
              label={t("reports:generalInformationStep:vehicleChangesLabel")}
              textFieldLabel={t("reports:generalInformationStep:changesLabel")}
              textFieldHint={t("reports:generalInformationStep:vehicleChangesExample")}
              textFieldPlaceholder={t("reports:generalInformationStep:vehicleChangesPlaceholder")}
              disabled={isLoading}
            />
          </Box>

          <Box mb={2}>
            <CollapseFieldset
              name="logisticsChanges"
              textFieldName="logisticsComments"
              label={t("reports:generalInformationStep:logisticsChangesLabel")}
              textFieldLabel={t("reports:generalInformationStep:changesLabel")}
              textFieldHint={t("reports:generalInformationStep:logisticsChangesExample")}
              textFieldPlaceholder={t("reports:generalInformationStep:logisticsChangesPlaceholder")}
              disabled={isLoading}
            />
          </Box>

          <Box mb={2}>
            <Typography
              variant="body1"
              fontWeight={500}
              mb={0.5}
            >
              {t("reports:generalInformationStep:employeesLabel")}
            </Typography>

            <TextFieldController
              type="number"
              name="employees"
              label=""
              placeholder="0"
              disabled={isLoading}
            />
          </Box>

          <Box mb={2}>
            <Box mb={2}>
              <Typography
                variant="body1"
                fontWeight={500}
                mb={0.5}
              >
                {t("reports:generalInformationStep:isFirstAuditLabel")}
              </Typography>
            
              <AutocompleteController
                name="isFirstAudit"
                label=""
                placeholder={t("form:placeholder:select")}
                options={options}
                disabled={isLoading}
              />
            </Box>

            <Box mb={2} width="100%">
              <Collapse
                in={!firstAuditSelected?.value}
              >
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    mb={0.5}
                  >
                    {t("reports:generalInformationStep:auditNumberLabel")}
                  </Typography>
                  
                  <TextFieldController
                    type="number"
                    name="number"
                    label=""
                    placeholder="0"
                    disabled={isLoading}
                  />
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            type="submit"
            size="small"
            disabled={isLoading}
            endIcon={isLoading ? <ButtonLoader /> : null}
          >
            {t("reports:generalInformationStep:submit")}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  )
}