import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { useDeleteSimulationMutation, useLazyGetAllSimulationsQuery } from "../../../../store/scenarioBackups/scenarioBackups.api";

const PAGE_SIZE = 10

export const useSimulationsTab = () => {
  const { scenarioId } = useOutletContext<{ scenarioId: number }>()
  const [searchQuery, setSearchQuery] = useState("")
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const [deleteSimulation] = useDeleteSimulationMutation()
  const [getSimulations, { data, isFetching: simulationsLoading }] = useLazyGetAllSimulationsQuery()

  const simulations = data?.data 
  const paginationSettings = data?.meta.pagination ? data.meta.pagination  : undefined
  const [page, setPage] = useState(1)

  useEffect(() => {
    getSimulations({
      scenarioId,
      includeCount: true,
      pageNumber: 1,
      pageSize: PAGE_SIZE
    })
  }, [])

  const handleSearch = (query: string) => {
    setPage(1)
    getSimulations({
      scenarioId,
      includeCount: true,
      search: query,
      pageNumber: 1,
      pageSize: PAGE_SIZE
    });
  }

  const handleDeleteSimulation = async (simulationId: number) => {
    try {
      await deleteSimulation({ scenarioId, simulationId })
    } catch(err) {
      // TODO: handle error
    }
  }

  const handleChangeQuery = (ev: ChangeEvent<HTMLInputElement>) => {
    const query = ev.target.value;
    setSearchQuery(query);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(() => {
      handleSearch(query);
    }, 500);

    setTypingTimeout(timeout);
  }

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
    await getSimulations({
      scenarioId,
      includeCount: true,
      search: searchQuery,
      pageNumber: newPage,
      pageSize: PAGE_SIZE
    });
  }

  useEffect(() => {
    if (paginationSettings?.TotalPages && paginationSettings.TotalPages < page) {
      handleChangePage(undefined, paginationSettings.TotalPages)
    }
  }, [paginationSettings?.TotalPages])

  return {
    searchQuery,
    page,
    simulations,
    simulationsLoading,
    paginationSettings,
    handleDeleteSimulation,
    handleChangeQuery,
    handleChangePage,
  }
}
